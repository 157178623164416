import { Component, BaseComponent, Prop, namespace } from '@zento-lib/components';
import type { ShopByCategoriesData } from 'theme/stores/cms/types';
import { Carousel, Slide } from '@zento-lib/components/Carousel';
import { AppContextStore, KEY as appContextKey } from 'theme/@types/zento/stores/applicationContext';

import { MainImage } from '../../../atom/MainImage/MainImage';
import { Link } from '../../../atom/Link/Link';
import { Button } from '../../../atom/Button/Button';

import type { IHomepageCategoriesListImage } from './CategoriesListImage.d';
import style from './style.scss';

const appContextStore = namespace<AppContextStore>(appContextKey);

/**
 * Homepage Category List Image
 *
 * Renders a list with specific categories links and images
 */
@Component({})
export class HomepageCategoriesListImage extends BaseComponent<IHomepageCategoriesListImage, unknown> {
  private static T = {
    seeAll: 'see_all',
  };

  /**
   * An object containing component data (heading title and components links and images)
   */
  @Prop({ type: Object, required: true })
  categories: ShopByCategoriesData;

  @appContextStore.Getter('isDesktop')
  isDesktop: boolean;

  data() {
    return {
      items: [],
      categoryType2: this.categories.listType === 'category2',
      categoryType6: this.categories.listType === 'category6',
    };
  }

  /**
   * Determines config image sizes
   */
  get imageSizes() {
    return this.$data.categoryType2 || this.$data.categoryType6
      ? this.extended.$config.zento.images.secondListCategories
      : this.extended.$config.zento.images.listCategories;
  }

  mounted() {
    this.$data.items = this.categories.items.sort((a: any, b: any) => a.position - b.position);
  }

  render() {
    const config = this.extended.$config.zento.images;
    const catFilterConfig =
      config.secondListCategories.layout === 'layout3' ||
      config.secondListCategories.layout === 'layout10' ||
      config.secondListCategories.layout === 'layout12'
        ? config.secondListCategories
        : this.extended.$config.zento.theme.category;

    return (
      <section
        class={{
          [style.categoriesListImage]: true,
          [style.categoriesListImage2]: this.$data.categoryType2,
          [style.categoriesListImage6]: this.$data.categoryType6,
          [style.layout1]: (config.secondListCategories.layout || config.listCategories.layout) === 'layout1',
          [style.layout2]: (config.secondListCategories.layout || config.listCategories.layout) === 'layout2',
          [style.layout3]: (config.secondListCategories.layout || config.listCategories.layout) === 'layout3',
          [style.layout10]: (config.secondListCategories.layout || config.listCategories.layout) === 'layout10',
          [style.layout12]: (config.secondListCategories.layout || config.listCategories.layout) === 'layout12',
        }}
        key='categories-filter'>
        <div
          class={{
            [style.container]: true,
            [style.smallContainer]: this.categories.width === 'small',
            [style.largeContainer]: this.categories.width === 'large',
            [style.fullContainer]: this.categories.width === 'full_width',
          }}>
          {this.categories.heading ? (
            <h2
              class={{
                [style.centerTitle]: this.categories.heading_position === 'center',
                [style.rightTitle]: this.categories.heading_position === 'right',
              }}
              key='top-categories-title'>
              {this.categories.heading}
              <div>
                {this.categories.seeAll ? (
                  this.categories.follow === 'follow_link' &&
                  this.categories.follow_link &&
                  this.categories.follow_link?.length ? (
                    <Button
                      styleType='secondary'
                      handler={() => this.$router.push(this.extended.localizedRoute(this.categories.follow_link))}
                      class={{
                        [style.followBtn]: true,
                        [style.seeAll]: true,
                        [style.centerSeeAll]: this.categories.seeAllPosition === 'center',
                        [style.rightSeeAll]: this.categories.seeAllPosition === 'right',
                      }}>
                      <span>{this.getTranslation({ id: HomepageCategoriesListImage.T.seeAll })}</span>
                    </Button>
                  ) : (
                    <Link
                      to={{
                        name: 'Categories Widget List',
                        params: {
                          heading: this.categories.heading,
                          categories: this.categories,
                        },
                      }}
                      class={{
                        [style.seeAll]: true,
                        [style.centerSeeAll]: this.categories.seeAllPosition === 'center',
                        [style.rightSeeAll]: this.categories.seeAllPosition === 'right',
                      }}
                      key='see-all'>
                      {this.getTranslation({ id: HomepageCategoriesListImage.T.seeAll })}
                    </Link>
                  )
                ) : null}
              </div>
            </h2>
          ) : null}

          <div class={style.categoriesBox}>
            {catFilterConfig.categoriesFilterCarousel ? (
              <Carousel
                perPage={5}
                perPageCustom={[
                  [320, catFilterConfig.mobileCarouselItemsPortrait ?? 1.5],
                  [480, catFilterConfig.mobileCarouselItemsLandscape ?? 2],
                  [767, catFilterConfig.tabletCarouselItems ?? 3],
                  [1025, catFilterConfig.desktopCarouselItems ?? 5],
                ]}
                mouseDrag={true}
                navigationEnabled={true}
                paginationEnabled={true}
                centerMode={true}
                scrollPerPage={this.isDesktop}
                easing='ease-in-out'
                speed={this.isDesktop ? 500 : 200}
                class={style.categoriesItems}>
                {this.$data.items.map((c) => {
                  const image = {
                    src: ('widget_image' in c && c.widget_image ? c.widget_image : c.image) as any,
                    loading: '',
                  };

                  return (
                    <Slide
                      key={c.name}
                      class={{
                        [style.categoryItem]: true,
                        [style.hoverAnimation]: this.categories.imageHoverAnimation === 'hoverAnimation',
                        [style.hoverOverlay]: this.categories.imageHoverAnimation === 'hoverOverlay',
                        [style.buttonIcon]: this.categories.buttonType === 'icon',
                        [style.buttonIconText]: this.categories.buttonType === 'iconWithText',
                      }}>
                      <Link to={'/' + c.url_path} title={c.name}>
                        <MainImage
                          image={image}
                          folder=''
                          width={this.imageSizes.width}
                          height={this.imageSizes.height}
                          tabletWidth={this.imageSizes.tabletWidth}
                          tabletHeight={this.imageSizes.tabletHeight}
                          desktopWidth={this.imageSizes.desktopWidth}
                          desktopHeight={this.imageSizes.desktopHeight}
                          resize={config.secondListCategories.layout === 'layout10' ? 'xc' : 'xf'}
                          key='category-image'
                        />

                        {config.secondListCategories.layout === 'layout10' &&
                        this.categories.showCategoryTitle !== 'false' &&
                        this.categories.buttonType !== 'categoryName' ? (
                          <h3>{c.name}</h3>
                        ) : null}

                        {config.secondListCategories.layout !== 'layout3' &&
                        config.secondListCategories.layout !== 'layout10' ? (
                          <span class={style.button}>
                            {this.categories.buttonText ? (
                              <span>{this.categories.buttonText}</span>
                            ) : this.categories.showCategoryTitle !== 'false' ? (
                              <span>{c.name}</span>
                            ) : null}
                          </span>
                        ) : null}
                      </Link>
                    </Slide>
                  );
                })}
              </Carousel>
            ) : (
              <ul class={style.categoriesItems}>
                {this.$data.items.map((c) => {
                  const image = {
                    src: ('widget_image' in c && c.widget_image ? c.widget_image : c.image) as any,
                    loading: '',
                  };

                  return (
                    <li
                      class={{
                        [style.categoryItem]: true,
                        [style.hoverAnimation]: this.categories.imageHoverAnimation === 'hoverAnimation',
                        [style.hoverOverlay]: this.categories.imageHoverAnimation === 'hoverOverlay',
                        [style.buttonIcon]: this.categories.buttonType === 'icon',
                        [style.buttonIconText]: this.categories.buttonType === 'iconWithText',
                      }}>
                      <Link to={'/' + c.url_path}>
                        <MainImage
                          image={image}
                          folder=''
                          width={this.imageSizes.width}
                          height={this.imageSizes.height}
                          tabletWidth={this.imageSizes.tabletWidth}
                          tabletHeight={this.imageSizes.tabletHeight}
                          desktopWidth={this.imageSizes.desktopWidth}
                          desktopHeight={this.imageSizes.desktopHeight}
                          key='category-image'
                        />

                        {config.secondListCategories.layout === 'layout10' &&
                        this.categories.showCategoryTitle !== 'false' &&
                        this.categories.buttonType !== 'categoryName' ? (
                          <h3>{c.name}</h3>
                        ) : null}

                        {config.secondListCategories.layout !== 'layout3' &&
                        config.secondListCategories.layout !== 'layout10' ? (
                          <span class={style.button}>
                            {config.secondListCategories.layout === 'layout3' ? (
                              <span>{this.categories.buttonText}</span>
                            ) : this.categories.buttonText ? (
                              <span>{this.categories.buttonText}</span>
                            ) : this.categories.showCategoryTitle !== 'false' ? (
                              <span>{c.name}</span>
                            ) : null}
                          </span>
                        ) : null}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>

          {!this.categories.heading && this.categories.seeAll ? (
            this.categories.follow === 'follow_link' &&
            this.categories.follow_link &&
            this.categories.follow_link?.length ? (
              <Button
                styleType='secondary'
                handler={() => this.$router.push(this.extended.localizedRoute(this.categories.follow_link))}
                class={{
                  [style.followBtn]: true,
                  [style.seeAll]: true,
                  [style.centerSeeAll]: this.categories.seeAllPosition === 'center',
                  [style.rightSeeAll]: this.categories.seeAllPosition === 'right',
                }}>
                <span>{this.getTranslation({ id: HomepageCategoriesListImage.T.seeAll })}</span>
              </Button>
            ) : (
              <Link
                to={{
                  name: 'Categories Widget List',
                  params: {
                    heading: this.categories.heading,
                    categories: this.categories,
                  },
                }}
                class={{
                  [style.seeAll]: true,
                  [style.centerSeeAll]: this.categories.seeAllPosition === 'center',
                  [style.rightSeeAll]: this.categories.seeAllPosition === 'right',
                }}
                key='see-all'>
                {this.getTranslation({ id: HomepageCategoriesListImage.T.seeAll })}
              </Link>
            )
          ) : null}
        </div>
      </section>
    );
  }
}
