import { Component, BaseComponent, Prop, namespace } from '@zento-lib/components';
import type { ShopByCategoriesData } from 'theme/stores/cms/types';
import { decodePhpHtmlString } from '@zento-lib/util/html';
import { AppContextStore, KEY as appKey } from 'theme/@types/zento/stores/applicationContext';
import { Carousel, Slide } from '@zento-lib/components/Carousel';

import { InnerHTML } from '../../../atom/InnerHTML';
import { MainImage } from '../../../atom/MainImage/MainImage';
import { Link } from '../../../atom/Link/Link';
import { Button } from '../../../atom/Button/Button';

import type { IHomepageShopByCategories } from './ShopByCategories.d';
import style from './style.scss';

const appContextStore = namespace<AppContextStore>(appKey);

/**
 * Homepage Shop by Categories
 *
 * Renders a list with specific categories links and images added by shop administrator
 */
@Component({})
export class HomepageShopByCategories extends BaseComponent<IHomepageShopByCategories, unknown> {
  private static T = {
    seeAll: 'see_all',
  };

  /**
   * An object containing component data (heading title and components links and images)
   */
  @Prop({ type: Object, required: true })
  categories: ShopByCategoriesData;

  @appContextStore.Getter('isDesktop')
  private isDesktop: boolean;

  data() {
    return {
      items: [],
    };
  }

  /**
   * Determines config image sizes
   */
  private get imageSizes() {
    return this.extended.$config.zento.images.shopByCategories;
  }

  mounted() {
    this.$data.items = this.categories.items.sort((a: any, b: any) => a.position - b.position);
  }

  render() {
    const config = this.extended.$config.zento.images.shopByCategories;

    return (
      <section
        class={{
          [style.bannerCategories]: true,
          [style.layout1]: config.layout === 'layout1',
          [style.layout2]: config.layout === 'layout2',
          [style.layout3]: config.layout === 'layout3',
          [style.layout10]: config.layout === 'layout10',
        }}
        key='shop-by-categories-data'>
        {this.categories.heading ? (
          <div class={style.container}>
            <h2
              class={{
                [style.centerTitle]: this.categories.heading_position === 'center',
                [style.rightTitle]: this.categories.heading_position === 'right',
              }}
              key='top-categories-title'>
              {this.categories.heading}
              <div>
                {this.categories.seeAll ? (
                  this.categories.follow === 'follow_link' &&
                  this.categories.follow_link &&
                  this.categories.follow_link?.length ? (
                    <Button
                      styleType='ghost'
                      handler={() => this.$router.push(this.extended.localizedRoute(this.categories.follow_link))}
                      class={{
                        [style.followBtn]: true,
                        [style.seeAll]: true,
                        [style.centerSeeAll]: this.categories.seeAllPosition === 'center',
                        [style.rightSeeAll]: this.categories.seeAllPosition === 'right',
                      }}>
                      <span>{this.getTranslation({ id: HomepageShopByCategories.T.seeAll })}</span>
                    </Button>
                  ) : (
                    <Link
                      to={{
                        name: 'Categories Widget List',
                        params: {
                          heading: this.categories.heading,
                          categories: this.categories,
                        },
                      }}
                      class={{
                        [style.seeAll]: true,
                        [style.centerSeeAll]: this.categories.seeAllPosition === 'center',
                        [style.rightSeeAll]: this.categories.seeAllPosition === 'right',
                      }}
                      key='see-all'>
                      {this.getTranslation({ id: HomepageShopByCategories.T.seeAll })}
                    </Link>
                  )
                ) : null}
              </div>
            </h2>
          </div>
        ) : null}

        {!this.isDesktop && config.categoriesFilterCarousel ? (
          <Carousel
            perPage={4}
            perPageCustom={[
              [320, config.mobileCarouselItemsPortrait ?? 1.5],
              [480, config.mobileCarouselItemsLandscape ?? 2],
              [767, config.tabletCarouselItems ?? 3],
              [1025, config.desktopCarouselItems ?? 4],
            ]}
            mouseDrag={true}
            navigationEnabled={true}
            paginationEnabled={true}
            centerMode={true}
            autoplay={config.carouselAutoplay && this.isDesktop}
            loop={config.carouselLoop && this.isDesktop}
            easing='ease-in-out'
            speed={200}
            class={style.grid}>
            {this.$data.items.map((c) => {
              const image = {
                src: ('widget_image' in c && c.widget_image ? c.widget_image : c.image) as any,
                loading: '',
              };

              return (
                <Slide
                  class={{
                    [style.categoryBanner]: true,
                    [style.hoverAnimation]: this.categories.imageHoverAnimation === 'hoverAnimation',
                    [style.hoverOverlay]: this.categories.imageHoverAnimation === 'hoverOverlay',
                    [style.positionNone]: this.categories.buttonPosition === 'positionNone',
                  }}
                  key={c.title}>
                  <figure>
                    <Link to={c.url_path} key='category-item-img-wrapper'>
                      <MainImage
                        image={image}
                        folder=''
                        width={this.imageSizes.width}
                        height={this.imageSizes.height}
                        tabletWidth={this.imageSizes.tabletWidth}
                        tabletHeight={this.imageSizes.tabletHeight}
                        desktopWidth={this.imageSizes.desktopWidth}
                        desktopHeight={this.imageSizes.desktopHeight}
                      />
                    </Link>
                    <div
                      class={{
                        [style.bannerContentLink]: true,
                        [style.topLeft]: this.categories.buttonPosition === 'topLeft',
                        [style.centerLeft]: this.categories.buttonPosition === 'centerLeft',
                        [style.topCenter]: this.categories.buttonPosition === 'topCenter',
                        [style.center]: this.categories.buttonPosition === 'center',
                        [style.topRight]: this.categories.buttonPosition === 'topRight',
                        [style.centerRight]: this.categories.buttonPosition === 'centerRight',
                        [style.bottomLeft]: this.categories.buttonPosition === 'bottomLeft',
                        [style.bottomCenter]: this.categories.buttonPosition === 'bottomCenter',
                        [style.bottomRight]: this.categories.buttonPosition === 'bottomRight',
                      }}>
                      {this.categories.showCategoryTitle !== 'false' ? (
                        <h2>
                          <Link to={c.url_path} key='category-item-title'>
                            {c.name}
                          </Link>
                        </h2>
                      ) : null}

                      {this.categories.showCategoryDescription === 'true' && c.description && c.description.length ? (
                        <InnerHTML
                          class={style.description}
                          contents={decodePhpHtmlString(
                            c.description.length <= 100 ? c.description : c.description.slice(0, 100) + '...',
                          )}
                        />
                      ) : null}

                      {config.layout !== 'layout10' ? (
                        <Button
                          class={{
                            [style.button]: true,
                            [style.buttonIcon]: this.categories.buttonType === 'icon',
                            [style.buttonIconText]: this.categories.buttonType === 'iconWithText',
                          }}
                          styleType='tertiary'
                          handler={() => this.$router.push(this.extended.localizedRoute('/' + c.url_path))}
                          key='category-item-link'>
                          {this.categories.buttonText ? (
                            <span>{this.categories.buttonText}</span>
                          ) : this.categories.showCategoryTitle !== 'false' ? (
                            <span>{c.name}</span>
                          ) : null}
                        </Button>
                      ) : null}
                    </div>
                  </figure>
                </Slide>
              );
            })}
            ;
          </Carousel>
        ) : (
          <ul class={style.grid}>
            {this.$data.items.map((c) => {
              const image = {
                src: ('widget_image' in c && c.widget_image ? c.widget_image : c.image) as any,
                loading: '',
              };

              return (
                <li
                  class={{
                    [style.categoryBanner]: true,
                    [style.hoverAnimation]: this.categories.imageHoverAnimation === 'hoverAnimation',
                    [style.hoverOverlay]: this.categories.imageHoverAnimation === 'hoverOverlay',
                    [style.positionNone]: this.categories.buttonPosition === 'positionNone',
                  }}
                  key={c.title}>
                  <figure>
                    <Link to={c.url_path} key='category-item-img-wrapper'>
                      <MainImage
                        image={image}
                        folder=''
                        width={this.imageSizes.width}
                        height={this.imageSizes.height}
                        tabletWidth={this.imageSizes.tabletWidth}
                        tabletHeight={this.imageSizes.tabletHeight}
                        desktopWidth={this.imageSizes.desktopWidth}
                        desktopHeight={this.imageSizes.desktopHeight}
                      />
                    </Link>
                    <div
                      class={{
                        [style.bannerContentLink]: true,
                        [style.topLeft]: this.categories.buttonPosition === 'topLeft',
                        [style.centerLeft]: this.categories.buttonPosition === 'centerLeft',
                        [style.topCenter]: this.categories.buttonPosition === 'topCenter',
                        [style.center]: this.categories.buttonPosition === 'center',
                        [style.topRight]: this.categories.buttonPosition === 'topRight',
                        [style.centerRight]: this.categories.buttonPosition === 'centerRight',
                        [style.bottomLeft]: this.categories.buttonPosition === 'bottomLeft',
                        [style.bottomCenter]: this.categories.buttonPosition === 'bottomCenter',
                        [style.bottomRight]: this.categories.buttonPosition === 'bottomRight',
                      }}>
                      {this.categories.showCategoryTitle !== 'false' ? (
                        <h2>
                          <Link to={c.url_path} key='category-item-title'>
                            {c.name}
                          </Link>
                        </h2>
                      ) : null}

                      {this.categories.showCategoryDescription === 'true' && c.description && c.description.length ? (
                        <InnerHTML
                          class={style.description}
                          contents={decodePhpHtmlString(
                            c.description.length <= 100 ? c.description : c.description.slice(0, 100) + '...',
                          )}
                        />
                      ) : null}

                      {config.layout !== 'layout10' ? (
                        <Button
                          class={{
                            [style.button]: true,
                            [style.buttonIcon]: this.categories.buttonType === 'icon',
                            [style.buttonIconText]: this.categories.buttonType === 'iconWithText',
                          }}
                          styleType='tertiary'
                          handler={() => this.$router.push(this.extended.localizedRoute('/' + c.url_path))}
                          key='category-item-link'>
                          {this.categories.buttonText ? (
                            <span>{this.categories.buttonText}</span>
                          ) : this.categories.showCategoryTitle !== 'false' ? (
                            <span>{c.name}</span>
                          ) : null}
                        </Button>
                      ) : null}
                    </div>
                  </figure>
                </li>
              );
            })}
          </ul>
        )}
      </section>
    );
  }
}
