import { Component, BaseComponent, Prop } from '@zento-lib/components';
import type { ShopByCategoriesData } from 'theme/stores/cms/types';

import { Button } from '../../../atom/Button/Button';
import { Link } from '../../../atom/Link/Link';
import { MainImage } from '../../../atom/MainImage/MainImage';

import type { IHomepageCategoriesList } from './CategoriesList.d';
import style from './style.scss';

/**
 * Homepage Category List
 *
 * Renders a list with specific categories links
 */
@Component({})
export class HomepageCategoriesList extends BaseComponent<IHomepageCategoriesList, unknown> {
  private static T = {
    seeAll: 'see_all',
  };

  /**
   * An object containing component data (heading title and components links and images)
   */
  @Prop({ type: Object, required: true })
  categories: ShopByCategoriesData;

  data() {
    return {
      items: [],
    };
  }

  mounted() {
    this.$data.items = this.categories.items.sort((a: any, b: any) => a.position - b.position);
  }

  render() {
    const config = this.extended.$config.zento.images.categoriesList;

    return (
      <section
        class={{
          [style.categoryList]: true,
          [style.layout10]: config.layout === 'layout10',
        }}
        key='categories-list'>
        <div class={style.container}>
          <div>
            {this.categories.heading ? (
              <h2
                class={{
                  [style.centerTitle]: this.categories.heading_position === 'center',
                  [style.rightTitle]: this.categories.heading_position === 'right',
                }}
                key='top-categories-title'>
                {this.categories.heading}
                <div>
                  {this.categories.seeAll ? (
                    this.categories.follow === 'follow_link' &&
                    this.categories.follow_link &&
                    this.categories.follow_link?.length ? (
                      <Button
                        styleType='ghost'
                        handler={() => this.$router.push(this.extended.localizedRoute(this.categories.follow_link))}
                        class={{
                          [style.followBtn]: true,
                          [style.seeAll]: true,
                          [style.centerSeeAll]: this.categories.seeAllPosition === 'center',
                          [style.rightSeeAll]: this.categories.seeAllPosition === 'right',
                        }}>
                        <span>{this.getTranslation({ id: HomepageCategoriesList.T.seeAll })}</span>
                      </Button>
                    ) : (
                      <Link
                        to={{
                          name: 'Categories Widget List',
                          params: {
                            heading: this.categories.heading,
                            categories: this.categories,
                          },
                        }}
                        class={{
                          [style.seeAll]: true,
                          [style.centerSeeAll]: this.categories.seeAllPosition === 'center',
                          [style.rightSeeAll]: this.categories.seeAllPosition === 'right',
                        }}
                        key='see-all'>
                        {this.getTranslation({ id: HomepageCategoriesList.T.seeAll })}
                      </Link>
                    )
                  ) : null}
                </div>
              </h2>
            ) : null}
            <div class={style.topCategoriesBox} key='top-categories-data'>
              <ul>
                {this.$data.items.map((c) => {
                  const image = {
                    src: ('widget_image' in c && c.widget_image ? c.widget_image : c.image) as any,
                    loading: '',
                  };

                  return (
                    <li class={style.categorieItem} key={c.name}>
                      {config.layout === 'layout10' ? (
                        <Link to={c.url_path} key={'category-item-img-wrapper-' + c.url_key}>
                          <MainImage
                            image={image}
                            folder=''
                            width={config.width}
                            height={config.height}
                            tabletWidth={config.tabletWidth}
                            tabletHeight={config.tabletHeight}
                            desktopWidth={config.desktopWidth}
                            desktopHeight={config.desktopHeight}
                            heightFallback={0}
                          />
                        </Link>
                      ) : (
                        <Button
                          class={{
                            [style.button]: true,
                            [style.buttonIconText]: this.categories.buttonType === 'iconWithText',
                          }}
                          styleType='tertiary'
                          handler={() => this.$router.push(this.extended.localizedRoute('/' + c.url_path))}>
                          {this.categories.buttonText ? (
                            <span>{this.categories.buttonText}</span>
                          ) : this.categories.showCategoryTitle !== 'false' ? (
                            <span>{c.name}</span>
                          ) : null}
                        </Button>
                      )}
                    </li>
                  );
                })}
              </ul>

              {!this.categories.heading && this.categories.seeAll ? (
                this.categories.follow === 'follow_link' &&
                this.categories.follow_link &&
                this.categories.follow_link?.length ? (
                  <Button
                    styleType='ghost'
                    handler={() => this.$router.push(this.extended.localizedRoute(this.categories.follow_link))}
                    class={{
                      [style.followBtn]: true,
                      [style.seeAll]: true,
                      [style.centerSeeAll]: this.categories.seeAllPosition === 'center',
                      [style.rightSeeAll]: this.categories.seeAllPosition === 'right',
                    }}>
                    <span>{this.getTranslation({ id: HomepageCategoriesList.T.seeAll })}</span>
                  </Button>
                ) : (
                  <Link
                    to={{
                      name: 'Categories Widget List',
                      params: {
                        heading: this.categories.heading,
                        categories: this.categories,
                      },
                    }}
                    class={{
                      [style.seeAll]: true,
                      [style.centerSeeAll]: this.categories.seeAllPosition === 'center',
                      [style.rightSeeAll]: this.categories.seeAllPosition === 'right',
                    }}
                    key='see-all'>
                    {this.getTranslation({ id: HomepageCategoriesList.T.seeAll })}
                  </Link>
                )
              ) : null}
            </div>
          </div>
        </div>
      </section>
    );
  }
}
