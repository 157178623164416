import { Component, BaseComponent, Prop, namespace } from '@zento-lib/components';
import type { ShopByCategoriesData } from 'theme/stores/cms/types';
import { Carousel, Slide } from '@zento-lib/components/Carousel';
import { AppContextStore, KEY as appContextKey } from 'theme/@types/zento/stores/applicationContext';

import { MainImage } from '../../../atom/MainImage/MainImage';
import { Link } from '../../../atom/Link/Link';
import { Button } from '../../../atom/Button/Button';
import { InnerHTML } from '../../../atom/InnerHTML';

import type { IHomepageCategoriesBanner } from './CategoriesBanner.d';
import style from './style.scss';

const appContextStore = namespace<AppContextStore>(appContextKey);

/**
 * Homepage Categories Banners
 *
 * Renders a list with specific categories links and images added by shop administrator
 */
@Component({})
export class HomepageCategoriesBanner extends BaseComponent<IHomepageCategoriesBanner, unknown> {
  private static T = {
    seeAll: 'see_all',
  };

  /**
   * An object containing component data (heading title and components links and images)
   */
  @Prop({ type: Object, required: true })
  categories: ShopByCategoriesData;

  @appContextStore.Getter('isDesktop')
  isDesktop: boolean;

  data() {
    return {
      items: [],
    };
  }

  /**
   * Determines config image sizes
   */
  get imageSizes() {
    return this.extended.$config.zento.images.bannerCategories;
  }

  mounted() {
    this.$data.items = this.categories.items.sort((a: any, b: any) => a.position - b.position);
  }

  render() {
    const config = this.extended.$config.zento.images;
    const catFilterConfig = config.bannerCategories;

    return (
      <section
        class={{
          [style.categoriesBanner]: true,
          [style.layout1]: this.imageSizes.layout === 'layout1',
          [style.layout2]: this.imageSizes.layout === 'layout2',
          [style.layout3]: this.imageSizes.layout === 'layout3',
        }}
        key='categories-banner'>
        <div
          class={{
            [style.container]: true,
            [style.smallContainer]: this.categories.width === 'small',
            [style.largeContainer]: this.categories.width === 'large',
            [style.fullContainer]: this.categories.width === 'full_width',
          }}>
          {this.categories.heading ? (
            <h2
              class={{
                [style.centerTitle]: this.categories.heading_position === 'center',
                [style.rightTitle]: this.categories.heading_position === 'right',
              }}
              key='top-categories-title'>
              {this.categories.heading}
              <div>
                {this.categories.seeAll ? (
                  this.categories.follow === 'follow_link' &&
                  this.categories.follow_link &&
                  this.categories.follow_link?.length ? (
                    <Button
                      styleType='ghost'
                      handler={() => this.$router.push(this.extended.localizedRoute(this.categories.follow_link))}
                      class={{
                        [style.followBtn]: true,
                        [style.seeAll]: true,
                        [style.centerSeeAll]: this.categories.seeAllPosition === 'center',
                        [style.rightSeeAll]: this.categories.seeAllPosition === 'right',
                      }}>
                      <span>{this.getTranslation({ id: HomepageCategoriesBanner.T.seeAll })}</span>
                    </Button>
                  ) : (
                    <Link
                      to={{
                        name: 'Categories Widget List',
                        params: {
                          heading: this.categories.heading,
                          categories: this.categories,
                        },
                      }}
                      class={{
                        [style.seeAll]: true,
                        [style.centerSeeAll]: this.categories.seeAllPosition === 'center',
                        [style.rightSeeAll]: this.categories.seeAllPosition === 'right',
                      }}
                      key='see-all'>
                      {this.getTranslation({ id: HomepageCategoriesBanner.T.seeAll })}
                    </Link>
                  )
                ) : null}
              </div>
            </h2>
          ) : null}
        </div>

        {catFilterConfig.categoriesFilterCarousel ? (
          <Carousel
            perPage={5}
            perPageCustom={[
              [320, catFilterConfig.mobileCarouselItemsPortrait ?? 1.5],
              [480, catFilterConfig.mobileCarouselItemsLandscape ?? 2],
              [767, catFilterConfig.tabletCarouselItems ?? 3],
              [1025, catFilterConfig.desktopCarouselItems ?? 5],
            ]}
            mouseDrag={true}
            navigationEnabled={true}
            paginationEnabled={false}
            centerMode={true}
            scrollPerPage={this.isDesktop}
            easing='ease-in-out'
            speed={this.isDesktop ? 500 : 200}
            class={style.bannersCarousel}>
            {this.$data.items.map((category) => {
              const image = {
                src: ('widget_image' in category && category.widget_image
                  ? category.widget_image
                  : category.image) as any,
                loading: '',
              };

              return (
                <Slide
                  key={category.name}
                  class={{
                    [style.bannerItem]: true,
                    [style.gradient]: this.categories.gradient,
                    [style.hoverAnimation]: this.categories.imageHoverAnimation === 'hoverAnimation',
                    [style.hoverOverlay]: this.categories.imageHoverAnimation === 'hoverOverlay',
                    [style.positionNone]: this.categories.buttonPosition === 'positionNone',
                  }}>
                  <Link to={'/' + category.url_path}>
                    <MainImage
                      image={image}
                      folder=''
                      width={this.imageSizes.width}
                      height={this.imageSizes.height}
                      tabletWidth={this.imageSizes.tabletWidth}
                      tabletHeight={this.imageSizes.tabletHeight}
                      desktopWidth={this.imageSizes.desktopWidth}
                      desktopHeight={this.imageSizes.desktopHeight}
                      key='category-banner-img'
                    />
                  </Link>
                  <div
                    class={{
                      [style.bannerContent]: true,
                      [style.positionNone]: this.categories.buttonPosition === 'positionNone',
                      [style.topLeft]: this.categories.buttonPosition === 'topLeft',
                      [style.centerLeft]: this.categories.buttonPosition === 'centerLeft',
                      [style.topCenter]: this.categories.buttonPosition === 'topCenter',
                      [style.center]: this.categories.buttonPosition === 'center',
                      [style.topRight]: this.categories.buttonPosition === 'topRight',
                      [style.centerRight]: this.categories.buttonPosition === 'centerRight',
                      [style.bottomLeft]: this.categories.buttonPosition === 'bottomLeft',
                      [style.bottomCenter]: this.categories.buttonPosition === 'bottomCenter',
                      [style.bottomRight]: this.categories.buttonPosition === 'bottomRight',
                      [style.hasDesc]:
                        this.categories.showCategoryDescription !== 'false' &&
                        category?.description &&
                        category?.description?.length,
                    }}
                    key='category-banner-data'>
                    <h3
                      class={{
                        [style.titleLayout1]: this.imageSizes.titleLayout === 'layout1',
                      }}
                      key='category-banner-title'>
                      {category.name}
                    </h3>
                    {this.categories.showCategoryDescription !== 'false' &&
                    category?.description &&
                    category?.description?.length ? (
                      <InnerHTML
                        contents={category.description.slice(0, this.isDesktop ? 250 : 50) + '...'}
                        class={style.categoryDesc}
                      />
                    ) : null}
                    <Button
                      styleType='primary'
                      handler={() => this.$router.push(this.extended.localizedRoute('/' + category.url_path))}
                      class={{
                        [style.button]: true,
                        [style.buttonIcon]: this.categories.buttonType === 'icon',
                        [style.buttonIconText]: this.categories.buttonType === 'iconWithText',
                      }}
                      key='category-banner-link'>
                      {this.categories.buttonText ? (
                        <span>{this.categories.buttonText}</span>
                      ) : this.categories.showCategoryTitle !== 'false' ? (
                        <span>{category.name}</span>
                      ) : null}
                    </Button>
                  </div>
                </Slide>
              );
            })}
          </Carousel>
        ) : (
          <div class={[style.grid, style.container]}>
            {this.$data.items.map((category) => {
              const image = {
                src: ('widget_image' in category && category.widget_image
                  ? category.widget_image
                  : category.image) as any,
                loading: '',
              };

              return (
                <div
                  class={{
                    [style.bannerItem]: true,
                    [style.hoverAnimation]: this.categories.imageHoverAnimation === 'hoverAnimation',
                    [style.hoverOverlay]: this.categories.imageHoverAnimation === 'hoverOverlay',
                    [style.positionNone]: this.categories.buttonPosition === 'positionNone',
                  }}
                  key={category.title}>
                  <Link to={'/' + category.url_path}>
                    <MainImage
                      image={image}
                      folder=''
                      width={this.imageSizes.width}
                      height={this.imageSizes.height}
                      tabletWidth={this.imageSizes.tabletWidth}
                      tabletHeight={this.imageSizes.tabletHeight}
                      desktopWidth={this.imageSizes.desktopWidth}
                      desktopHeight={this.imageSizes.desktopHeight}
                      key='category-banner-img'
                    />
                  </Link>
                  <div
                    class={{
                      [style.bannerContent]: true,
                      [style.topLeft]: this.categories.buttonPosition === 'topLeft',
                      [style.centerLeft]: this.categories.buttonPosition === 'centerLeft',
                      [style.topCenter]: this.categories.buttonPosition === 'topCenter',
                      [style.center]: this.categories.buttonPosition === 'center',
                      [style.topRight]: this.categories.buttonPosition === 'topRight',
                      [style.centerRight]: this.categories.buttonPosition === 'centerRight',
                      [style.bottomLeft]: this.categories.buttonPosition === 'bottomLeft',
                      [style.bottomCenter]: this.categories.buttonPosition === 'bottomCenter',
                      [style.bottomRight]: this.categories.buttonPosition === 'bottomRight',
                    }}
                    key='category-banner-data'>
                    <h3
                      class={{
                        [style.titleLayout1]: this.imageSizes.titleLayout === 'layout1',
                      }}
                      key='category-banner-title'>
                      {category.name}
                    </h3>
                    <Button
                      styleType='primary'
                      handler={() => this.$router.push(this.extended.localizedRoute('/' + category.url_path))}
                      class={{
                        [style.button]: true,
                        [style.buttonIcon]: this.categories.buttonType === 'icon',
                        [style.buttonIconText]: this.categories.buttonType === 'iconWithText',
                      }}
                      key='category-banner-link'>
                      {this.categories.buttonText ? (
                        <span>{this.categories.buttonText}</span>
                      ) : this.categories.showCategoryTitle !== 'false' ? (
                        <span>{category.name}</span>
                      ) : null}
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </section>
    );
  }
}
